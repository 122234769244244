import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import LayoutBase from '../layouts/LayoutBase'

const Servicios = ( ) => {
    return (
        <LayoutBase>
            
            <div className="container-fluid p-0">
                    <StaticImage
                        src="../images/slider/07.jpg"
                        className="d-block w-100 min-h-300"
                        alt="Servicios"
                        layout="fullWidth"
                    />
            </div>
            
            <main className="container py-5">
                <div className="row">
                    <div className="col-12">
                        <h1>Servicios del centro</h1>
                        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Corporis dolore nemo sequi debitis non optio ratione quae, nostrum quis officiis natus repellat nulla reiciendis quisquam libero quibusdam at ea distinctio! Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate provident dignissimos quod aliquam facere non explicabo ex facilis fugit eius perspiciatis unde, sed similique consequatur quas deserunt. Hic, omnis quod!</p>
                    </div>
                </div>
                
                <div className="row py-4">
                    <div className="col-md-4">
                        <ul>
                            <li><h3>Diagnostico</h3></li>
                            <li>TEA</li>
                            <li>Síndrome de Asperger</li>
                            <li>Discapacidad Intelectual</li>
                            <li>Altas Capacidades</li>
                            <li>TDA/H</li>

                            <li className="mt-4"><h3>Adultos</h3></li>
                            <li>Psicología Clínica Adultos</li>
                            <li>Duelo</li>
                            <li>Procesos de separación</li>
                            <li>Discapacidad</li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <ul>
                            <li><h3>Niños</h3></li>
                            <li>Atención Temprana</li>
                            <li>Discapacidad</li>
                            <li>Reeducación y Trastornos de Aprendizaje</li>
                            <li>Intervención específica en TEA y S. Asperger</li>
                            <li>Servicios Intensivos</li>
                            <li>Grupo de Habilidades Sociales</li>
                            <li>Psicología Clinica Infanto Juvenil</li>
                            <li>Procesos de separación</li>
                            <li>Procesos de Adopción y bienvenida al hogar</li>
                            <li>Duelo</li>
                            <li>Identidad de Género en la Infancia</li>
                            <li>Intervención en el Hogar</li>
                            <li>Intervención en Colegios</li>
                            <li>Asesoramiento Familiar</li>
                            <li>Asesoramiento Escolar</li>
                            <li>Coach de Sueño</li>
                            <li>Tratamiento On-line</li>
                            <li>Grupos de Juego</li>
                            <li>Talleres de Lectura</li>
                        </ul>

                    </div>
                    <div className="col-md-4">
                        <ul>
                            <li><h3>Colegios</h3></li>
                            <li>Formación</li>
                            <li>Asesoramiento de casos</li>
                            <li>Servicio externo de gabinete Psicopedagógico</li>
                            <li>Intervención en Aulas</li>
                            <li>Diseño e implantación de patios inclusivos</li>
                            <li>Servicios Intensivos de PT</li>

                            <li className="mt-4"><h3>Formación</h3></li>
                            <li>Formaciones</li>
                            <li>Charlas</li>
                            <li>Talleres</li>
                        </ul>

                    </div>
                </div>
                
            </main>
        </LayoutBase>
    )
}

export default Servicios
